
.rubric-screen .radioGroup {
	font-size: 110%;
	text-align: center;
}

.rubric-screen .radioGroupChoice.selected{
	font-size: 125%;
}

.rubric-screen textarea {
	width: 98%;
}

.rubric-screen .button-box {
	text-align: center;
}

.rubric-screen .button-box .btn-primary {
	font-size: 20pt;
}