.score-screen .rank-group {
	margin-top: 0.5em;
	padding: 0.25em;
	background-color: #ccc;
}

.score-screen .ranking {
	position: relative;
	display: flex;
}

.score-screen .ranking>div {
	padding: 0 0.5em;
	flex-grow: 0;
}

.score-screen .ranking > div:nth-child(1) {
	width: 4em;
	text-align: right;
}

.score-screen .ranking > *:nth-child(2) {
	flex-grow: 1;
}

.score-screen .tied > div:nth-child(1)::before {
	content: '⋈ ';
}

.score-screen input[type="number"] {
	text-align: right;
	width: 8em;
}

.score-screen .filtered {
	opacity: 0.25;
}