.event-landing-screen h3 {
	text-align: center !important;
	font-size: 22pt;
}

.event-landing-screen #content-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.event-landing-screen #list,
.event-landing-screen #viewer {
	padding: 1em;
}

.event-landing-screen #list {
	max-width: 900px;
}

.event-landing-screen #list > .section-card {
	display: flex;
	height: 100%;
}

.event-landing-screen #list > .section-card > .card-header {
	writing-mode: vertical-rl;
	padding: .5em;
}

.event-landing-screen #list .selected {
	background-color: var(--accent-1a);
}

.event-landing-screen .section-card {
	margin: 0;
}

.event-landing-screen .viewer-container {
}

.event-landing-screen #viewer {
	max-width: 600px;
	text-align: center;
	position: sticky;
	top: 0;
}

.event-landing-screen #viewer .card-body {
}

.event-landing-screen table {
	border-collapse: collapse;
}

.event-landing-screen thead, tbody {
	border: none;
}

.event-landing-screen thead td {
	padding-bottom: 1em;
}

.event-landing-screen table tbody tr:nth-child(even) {
	background: #eee;
}

.event-landing-screen table tbody tr:hover {
	background: var(--accent-2a);
}

.event-landing-screen td {
	padding: 0 .5em;
}


@media only screen and (max-width: 768px) {
	.event-landing-screen #list > .section-card {
		display: block;
	}

	.event-landing-screen #list > .section-card > .card-header {
		writing-mode: initial;
	}
}
