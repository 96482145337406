.event-screen .rubric-form {
	display: grid;
	grid-template-columns: 1fr min-content min-content min-content;
	grid-gap: 0.25em;
}

.rubric-form input[type=number] {
	width: 5em;
}

.event-screen .rubric-form>*:nth-child(5n+1) {
	border-top: 1px solid #444;
	border-left: 1px solid #444;
}

.event-screen .rubric-form>textarea {
	margin-bottom: 2em;
}

.event-screen .rubric-form>button{
	align-self: start;
	justify-self: center;
	margin-top: 1em;
	grid-column: 2 / span 3;
}

.event-screen .deleted {
	text-decoration: line-through;
	background: #fcc;
}

.radioGroupChoice-NA {
	display: block;
	margin: 0.25em auto;
	width: 5em;
}

.event-screen .table.tight .clickable {
	padding: 0.25em;
}