.home-screen>* {
	text-align: center;
	max-width: 900px;
	margin: auto;
}

.home-screen h1 {
	margin-top: 0;
}

.home-screen p {
	text-align: justify;
}

.home-screen .subtitle {
	font-size: 24pt;
	line-height: 1em;
}

.home-screen .main-button-box {
	display: inline-block;
	border: 8px solid #da0000;
	border-left: 0;
	border-right: 0;
	text-align: center;

	margin: 1em 0;
	padding: 1em 1em;
}

.home-screen .main-button-box .btn {
	font-size: 18pt;
	width: 12em;
	padding: .25em;
	margin: 0.5em 2em;
}

.home-screen .main-button-box .btn div {
	margin: .25em 1em;
}
