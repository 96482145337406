.judging-screen .score-button {
	font-size: 200%;
	box-shadow: .075em .075em .075em #aaa;
	float: right !important;
	margin-top: -1.25em;
	margin-right: 0;
	min-width: 2.6em;
}

.judging-screen .progressBar {
	width: 80%;
	margin: auto;
	border: 1px solid #555;
	background: #eee;
}