.event-assignments table {
	overflow: hidden;
	border-collapse: collapse;
}

.event-assignments td {
	text-align: center;
}

.event-assignments td,
.event-assignments th {
	position: relative;
	padding: 0 0.5em;
}

.event-assignments table.compact td,
.event-assignments table.compact th {
	padding: 0;
}

.event-assignments tbody>tr:hover {
	background-color: var(--accent-2a);
}

.event-assignments td:hover::after,
.event-assignments th:hover::after {
	content: "";
	position: absolute;
	border: 3px solid var(--accent-2a);
	background-color: var(--accent-2a);
	opacity: 0.33;
	left: 0;
	top: -5000px;
	height: 10000px;
	width: 100%;
	pointer-events: none;
}